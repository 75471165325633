import Head from "next/head";
import { useRouter } from "next/router";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";

import { appReducer, initialState } from "../../components/store/reducer";

export const appStoreContext = createContext({
  store: initialState,
  dispatch: (payload: any) => {},
  googleClientId: "",
  setGoogleClientId: (clientId: string) => {},
});

interface AppStoreContextProviderProps {
  googleClientId: string;
  setGoogleClientId: Dispatch<SetStateAction<string>>;
  children: ReactNode;
}

const AppStoreContextProvider: React.FC<AppStoreContextProviderProps> = ({
  googleClientId,
  setGoogleClientId,
  children,
}) => {
  const [state, dispatchHandler] = useReducer(appReducer, initialState);
  const [title, setTitle] = useState("");
  const router = useRouter();

  useEffect(() => {
    const { pathname } = router;
    if (pathname === "/" || pathname === "/home") {
      setTitle("Home");
    }
    // split first word of pathname and use capitalizeFirstLetter to make it title
    if (pathname.split("/")[1]) {
      const updatedPathName = pathname
        .split("/")[1]
        .split("-")
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      setTitle(updatedPathName);
    }
  }, [router.pathname]);

  const [store, dispatch] = useMemo(() => [state, dispatchHandler], [state]);
  const appStoreContextValue = {
    store,
    dispatch,
    googleClientId,
    setGoogleClientId,
  };
  return (
    <appStoreContext.Provider value={appStoreContextValue}>
      <Head>
        <title>{title ? `${title} - ` : ""}Topline Pro</title>
      </Head>
      {children}
    </appStoreContext.Provider>
  );
};

export const useAppStore = () => useContext(appStoreContext);

export default AppStoreContextProvider;
