import { signUpFormData } from "../types";
import { signUpFormInitialState } from "./../constants";

interface ISignUpFormData {
  signUpFormData: signUpFormData;
  isSubmitButtonDisabled: boolean;
  isFormValid: boolean;
  otpCode: string;
  showOtpModal: boolean;
  isSignedUp: boolean;
}

export const initialState: ISignUpFormData = {
  signUpFormData: signUpFormInitialState,
  isFormValid: false,
  showOtpModal: false,
  otpCode: "",
  isSubmitButtonDisabled: true,
  isSignedUp: false,
};
