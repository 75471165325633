import React from "react";
import { Portal } from "@headlessui/react";
import { ToastClassName, ToastContainer, cssTransition } from "react-toastify";
import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import useIsMobile from "../../hooks/useIsMobile";

const ToastifyComponent = () => {
  const isMobile = useIsMobile();
  const contextClass = {
    success: "bg-green-50 text-green-800",
    default: "bg-green-50 text-green-800",
    error: "bg-red-50 text-red-800",
    info: "bg-blue-50 text-blue-800",
    warning: "", // added for consistency of type
  };

  function getToastIcon(type: string) {
    if (type === "success") {
      return <CheckCircleIcon className="h-6 w-6 text-green-400" />;
    } else if (type === "error") {
      return <XCircleIcon className="h-6 w-6 text-red-600" />;
    } else if (type === "info") {
      return <InformationCircleIcon className="h-6 w-6 text-blue-400" />;
    }
  }

  const customSlide = cssTransition({
    enter: isMobile ? "animate__slideInTop" : "animate__slideInLeft",
    exit: isMobile ? "animate__slideOutTop" : "animate__slideOutLeft",
  });

  const getToastClassName: ToastClassName = (params) =>
    `${
      params?.type && contextClass[params.type]
    } z-50 flex items-center justify-center px-4 sm:py-3 py-2 my-1 rounded-lg text-sm-medium shadow-all-sides`;

  return (
    <>
      <Portal>
        <ToastContainer
          toastClassName={getToastClassName}
          position={isMobile ? "top-center" : "bottom-left"}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          hideProgressBar={true}
          autoClose={5000}
          icon={({ type }) => getToastIcon(type)}
          transition={customSlide}
        />
      </Portal>
    </>
  );
};

export default ToastifyComponent;
