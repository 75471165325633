import { useEffect, useState } from "react";

// Necessary because of NextJS's SSR
const debounce = (func: any, delay: number) => {
  let timer: any;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export default function useIsMobile(customBreakpoint?: number) {
  const mobileViewWidth = customBreakpoint || 768;
  const [isMobile, setIsMobile] = useState(
    typeof window !== "undefined" ? window.innerWidth < mobileViewWidth : false
  );

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < mobileViewWidth);
    };

    const debouncedCheckMobile = debounce(checkMobile, 200);

    window.addEventListener("resize", debouncedCheckMobile);
    window.addEventListener("orientationchange", debouncedCheckMobile);

    return () => {
      window.removeEventListener("resize", debouncedCheckMobile);
      window.removeEventListener("orientationchange", debouncedCheckMobile);
    };
  }, []);

  return isMobile;
}
