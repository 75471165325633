export const detectIOS = () => {
  if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    return true;
  } else {
    return false;
  }
};

export const detectChrome = () => {
  if (
    /WebKit/i.test(navigator.userAgent) &&
    /CriOS/i.test(navigator.userAgent)
  ) {
    return true;
  } else {
    return false;
  }
};

export const detectSafari = () => {
  if (
    /WebKit/i.test(navigator.userAgent) &&
    !/CriOS/i.test(navigator.userAgent)
  ) {
    return true;
  } else {
    return false;
  }
};

export const promptUser = (deferredPrompt) => {
  if (deferredPrompt) {
    deferredPrompt.prompt(); // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then(function (choiceResult) {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt");
      } else {
        console.log("User dismissed the A2HS prompt");
      }
      deferredPrompt = null;
    });
  } else {
    alert("You've already installed the Topline Pro app."); // ask Matthew where is this gonna ever show?
  }
};
