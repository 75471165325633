import { initialState as signUpInitialState } from "./../signup/store/initialState";
import {
  initialState as loginInitialState,
  loginReducer,
} from "../login/loginReducer";
import { signUpFormReducer } from "../signup/store/reducer";
import { combineReducers } from "./utils";

export const initialState = {
  login: loginInitialState,
  signUpForm: signUpInitialState,
};

export const appReducer = combineReducers({
  login: loginReducer,
  signUpForm: signUpFormReducer,
});
