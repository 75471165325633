import { IAction } from "./types";
import produce from "immer";
export const initialState = {
  isAuthenticated: false,
  user: {},
  token: "",
  error: "",
  loading: false,
  isAdmin: false,
  isUserExist: false,
};

export const loginReducer = (state = initialState, action: IAction) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "LOGIN_REQUEST":
        draft.loading = true;
        break;
      default:
        break;
    }
  });
