export default function MaintenancePage() {
  return (
    <div className="flex flex-col h-screen w-full bg-blue-900">
      <div className="flex justify-start items-center h-20 px-4">
        <img className="h-8" src="./topline-pro-white-text.svg" />
      </div>

      <div className="flex flex-col justify-center items-center pt-20">
        <img className="w-40 h-40" src="./maintenance.gif" />
        <div className="flex flex-col justify-center items-center pt-4 px-4">
          <h1 className="text-white text-center text-h2-semibold max-w-400">
            We're adding some cool new features. Check back shortly!
          </h1>
        </div>
      </div>
    </div>
  );
}
