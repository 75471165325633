import { signUpFormData } from "./types";

export const singUpFeatures = [
  "Custom website built for you",
  "No coding or technical skills required",
  "Unlimited edits",
  "24/7 dedicated support",
  "50+ 5-star reviews",
];

export const singUpFeaturesTitle =
  "Focus on what you want, leave the rest to us";

export const signUpFormInitialState: signUpFormData = {
  email: "",
  phoneNumber: "",
  firstName: "",
  lastName: "",
  companyName: "",
  zipCode: "",
  stripe_id: "",
  facebook: "",
  existing_website: "",
  landing_page_id: "",
  AUTH_O_ID: "",
  question_2: [],
  question_3: [],
  questions: "",
};

export const CONSTANTS = {
  SET_IS_SUBMIT_BUTTON_DISABLED: "SET_IS_SUBMIT_BUTTON_DISABLED",
  SET_FORM_VALIDATION_STATUS: "SET_FORM_VALIDATION_STATUS",
  SET_IS_SIGNED_UP: "SET_IS_SIGNED_UP",
  SET_AUTH0_ID: "SET_AUTH0_ID",

  UPDATE_SIGNUP_FORM_DATA: "UPDATE_SIGNUP_FORM_DATA",
};
