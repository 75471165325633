import { initialState } from "./initialState";
import produce from "immer";
import { CONSTANTS } from "../constants";

export const signUpFormReducer = (state = initialState, action: any) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CONSTANTS.UPDATE_SIGNUP_FORM_DATA:
        draft.signUpFormData[action.payload.name] = action.payload.value;
        break;
      case CONSTANTS.SET_IS_SUBMIT_BUTTON_DISABLED:
        draft.isSubmitButtonDisabled = action.payload;
        break;
      case CONSTANTS.SET_FORM_VALIDATION_STATUS:
        draft.isFormValid = action.payload;
        break;
      case CONSTANTS.SET_IS_SIGNED_UP:
        draft.isSignedUp = action.payload;
        break;
      case CONSTANTS.SET_AUTH0_ID:
        draft.signUpFormData.AUTHO_ID = action.payload;
        break;
      default:
        break;
    }
  });
